<template>
  <v-container
    fluid
    class="px-0 pt-0 pb-16"
  >
    <div class="hidden-sm-and-down section1">
      <div class="text-h1 sora black--text">
        We’ve got your
        answers to the most commonly asked questions
      </div>
    </div>
    <div class="hidden-sm-and-down section2">
      <div class="s2-title">
        <v-row class="d-flex justify-center">
          <v-col
            cols="12"
            class="d-flex justify-center"
          >
            <div class="section2-header">
              Frequently Asked Questions
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="d-flex justify-center">
            <div class="s2-sub">
              FAQ text.
            </div>
          </v-col>
        </v-row>
      </div>
      <v-row class="d-flex justify-center">
        <v-col
          cols="10"
          class="d-inline-flex"
        >
          <v-col class="d-flex justify-center">
            <v-expansion-panels>
              <v-expansion-panel>
                <v-expansion-panel-header>
                  Item 1
                  <template v-slot:actions>
                    <v-icon color="primary">
                      mdi-plus
                    </v-icon>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header>
                  Item 1
                  <template v-slot:actions>
                    <v-icon color="primary">
                      mdi-plus
                    </v-icon>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header>
                  Item 1
                  <template v-slot:actions>
                    <v-icon color="primary">
                      mdi-plus
                    </v-icon>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
        </v-col>
      </v-row>
    </div>
    <!-----------------------------------------TABLET---------------------------------------->
    <div v-show="tabletDevice">
      <div
        class="section1-tablet pb-16 small"
      >
        <sequential-entrance
          from-top
        >
          <v-row class="ml-16">
            <v-col class="pt-16">
              <div class="title-text-tablet">
                Get a <span class="primary--text font-weight-bold">pet</span> <br> insurance quote <br> and apply online
              </div>
            </v-col>
          </v-row>
        </sequential-entrance>
        <sequential-entrance
          from-bottom
        >
          <v-row
            class="d-flex justify-center btns-tablet mt-4"
          >
            <v-col
              cols="6"
              class="d-inline-flex align-center"
            >
              <v-img
                src="/img/pet-quote.png"
                class="pet-qt"
              ></v-img>
              <v-btn
                class="primary white--text font-weight-bold ml-n12"
                to="/pet/info"
                large
              >
                <span class="px-4">Pet Quote</span>
              </v-btn>
              <v-btn
                class="white learn font-weight-bold"
                to="/pet/learnmore"
                large
              >
                <span class="secondary--text">Learn More</span>
              </v-btn>
            </v-col>
          </v-row>
        </sequential-entrance>
      </div>
      <v-row>
        <v-col class="white spacer">
          <v-spacer></v-spacer>
        </v-col>
      </v-row>
      <div class="section2">
        <v-row>
          <v-col
            class="d-flex justify-center"
          >
            <div class="pet-text pa-4">
              Find other insurance coverage you need
            </div>
          </v-col>
        </v-row>
        <v-row class="d-flex justify-center pb-16">
          <v-col
            cols="11"
            class="pb-16"
          >
            <v-row class="d-flex justify-center">
              <v-col cols="2">
                <router-link to="/condo">
                  <v-img
                    src="/img/condo-badge.png"
                    class="zoom"
                  ></v-img>
                </router-link>
              </v-col>
              <v-col cols="2">
                <router-link to="/landlord">
                  <v-img
                    src="/img/landlord-badge.png"
                    class="zoom"
                  ></v-img>
                </router-link>
              </v-col>
              <v-col cols="2">
                <router-link to="/renters">
                  <v-img
                    src="/img/renters-badge.png"
                    class="zoom"
                  ></v-img>
                </router-link>
              </v-col>
              <v-col cols="2">
                <router-link to="/auto">
                  <v-img
                    src="/img/car-badge.png"
                    class="zoom"
                  ></v-img>
                </router-link>
              </v-col>
              <v-col cols="2">
                <router-link to="/business">
                  <v-img
                    src="/img/buisiness-badge.png"
                    class="zoom"
                  ></v-img>
                </router-link>
              </v-col>
              <v-col cols="2">
                <router-link to="/pet">
                  <v-img
                    src="/img/pet-badge.png"
                    class="zoom"
                  ></v-img>
                </router-link>
              </v-col>
            </v-row>
            <v-row class="d-flex justify-center text-caption ma-12 pa-6">
              All Rights Reserved. Trupanion is a registered trademark owned by Trupanion, Inc. Underwritten in Canada by Omega General Insurance Company and in the United States by American Pet Insurance Company, 6100-4th Ave S, Seattle, WA 98108.
            </v-row>
          </v-col>
        </v-row>
      </div>
    </div>
    <!-----------------------------------------MOBILE---------------------------------------->
    <div v-show="mobileDevice">
      <div
        class="section1-mobile pb-16 small"
      >
        <sequential-entrance
          from-top
        >
          <v-row class="d-flex justify-center">
            <v-col>
              <div class="text-h2 title-text-mobile pt-16 ml-6">
                Get a <span class="primary--text font-weight-bold">pet</span> <br> insurance quote <br> and apply online
              </div>
            </v-col>
          </v-row>
        </sequential-entrance>
        <sequential-entrance
          from-bottom
        >
          <v-row
            class="pt-8 mt-16"
          >
            <v-col
              cols="12"
              class="d-inline-flex align-center justify-center"
            >
              <v-img
                src="/img/pet-quote.png"
                class="pet-qt-mobile"
              ></v-img>
              <v-btn
                class="primary white--text font-weight-bold"
                to="/pet/info"
              >
                <span class="px-4">Pet Quote</span>
              </v-btn>
            </v-col>
          </v-row>
        </sequential-entrance>
      </div>
      <div class="section2 pb-4">
        <v-row class="d-flex justify-center pb-4">
          <v-col
            cols="12"
            class="pb-5"
          >
            <v-row class="d-flex justify-center text-caption ma-4 pa-2">
              <v-col
                cols="12"
                lg="8"
              >
                Trupanion loves informed decisions. See their <a
                  target="_blank"
                  href="https://trupanion.com/pet-insurance/policy/view-sample-policy-reord"
                >policy</a> for full coverage details. <a href="https://trupanion.com/pet-insurance/policy/view-sample-policy">https://trupanion.com/pet-insurance/policy/view-sample-policy</a>
              </v-col>

              <v-col
                cols="8"
              >
                All Rights Reserved. Trupanion is a registered trademark owned by Trupanion, Inc. Underwritten in Canada by Omega General Insurance Company and in the United States by American Pet Insurance Company, 6100-4th Ave S, Seattle, WA 98108. Terms and conditions apply, coverage may vary by state. Blanket is a separate entity and is not affiliated with Trupanion or American Pet Insurance Company.
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </div>
    </div>
  </v-container>
</template>

<script>
  export default {
    name: 'Pets',
    data () {
      return {
      }
    },
    computed: {
      mobileDevice () {
        return this.$vuetify.breakpoint.xsOnly
      },
      tabletDevice () {
        return this.$vuetify.breakpoint.smOnly
      },
    },
    created () {
      this.$gtag.pageview('Pets')
      this.$store.dispatch('loadPetAges')
      this.$store.dispatch('loadPetBreeds')
    },
    mounted () {
      this.$store.dispatch('resetPets')
    },
    methods: {
      sendevent () {
        this.$gtag.event('blanket', { method: 'Pet', campaign: 'none' })
        return 'completed'
      },
    },
  }
</script>

<style lang="scss" scoped>
.v-icon {
  font-size: 50px !important;
}

.apply-btn {
  font-family: 'Poppins';
font-style: normal;
font-weight: 700;
font-size: 22.6667px;
line-height: 125%;
/* or 28px */

text-align: center;
letter-spacing: 0.944445px;
text-transform: uppercase;

/* Color Palette/Dark Grey */

color: #292F36;
}

.s2-desc {
  font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 150%;
text-align: center;
color: #292F36;
}
  .s2-subhead {
    font-family: 'Sora';
font-style: normal;
font-weight: 800;
font-size: 30px;
line-height: 110%;
text-align: center;
letter-spacing: -0.424019px;
color: #005F6C;
  }
  .s2-sub {
    font-family: 'Poppins';
font-style: normal;
font-weight: 300;
font-size: 32px;
line-height: 120%;
text-align: center;
letter-spacing: -0.424019px;
color: #292F36;
  }

  .pet-icon-row {
    position: absolute;
    top: 280px;
  }
  .cta-btn {
    position: absolute;
    top: 467px;
    left: 130px;
    max-width: 372px !important;
  }

  .cta-txt {
    font-weight: 700;
    font-size: 17.9859px;
    line-height: 125%;
    text-align: center;
    letter-spacing: 0.749412px;
    text-transform: uppercase;
    color: #292F36;
    padding: 0 !important;
  }
  .sora {
position: absolute;
top: 100px;
left: 130px;
width: 600px;
font-family: 'Sora';
font-style: normal;
font-weight: 500;
font-size: 52px;
line-height: 110%;
color: #FFFFFF;
  }

  .section2-header {
font-family: 'Sora';
font-style: normal;
font-weight: 800;
font-size: 42px;
line-height: 110%;
text-align: center;
letter-spacing: -0.42px;
color: #292F36;
  }

  .s2-title {
    padding-top: 79px;
  }
.title-text-mobile {
    font-weight: 500;
    color: #616161;
  }
.cover{
  font-size: 55px;
  color: #757575;
}
.read-more{
  font-size: 18px;
  color: #00A1B7;
}
.quote-btn{
  font-weight: bold
}
.section1{
  position: relative;
  width: 100%;
  height: 500px;
  background: url("/img/dash-background.jpg") no-repeat;
  background-size: cover;
}
.section2 {
  position: relative;
height: 825px;
background: url('/img/pet-section2-background.png');
mix-blend-mode: normal;
}
.spacer {
  height: 6rem;
  z-index: 2;
}
.title-text-small {
position: absolute;
top: 26rem;
text-align: center;
margin-left: 2rem;
font-size: 50px;
font-weight: 500;
line-height: 132.19%;
z-index: 3 !important;
color: #616161;
}

.title-text-tablet {
  font-size: 60px;
  font-weight: 500;
  line-height: 132.19%;
  color: #616161;
  }
.title-text-large {
position: absolute;
top: 34rem;
margin-left: 3rem;
text-align: center;
font-size: 60px;
font-weight: 500;
line-height: 132.19%;
z-index: 3 !important;
color: #616161;
}
.pet-text {
font-style: normal;
font-weight: normal;
font-size: 60px;
line-height: 70px;
text-align: center;
letter-spacing: -0.005em;
color: #757575;
z-index: 1;
background-color: white;
}
.btns-tablet {
  z-index: 3;
  position: absolute;
  left: -4rem;
  text-align: center;
}
.btns-small {
  z-index: 3;
  position: absolute;
  top: 37rem;
  left: -4rem;
  text-align: center;
}
.btns-large {
  z-index: 3;
  position: absolute;
  top: 50rem;
  left: -1rem;
  text-align: center;
}
.learn {
  border: 1px solid #00A1B7 !important;
  color: #00A1B7 !important;
}
.pet-qt {
  width:  200;
  height: auto;
  z-index: 5;
}
.btn-mrg1 {
  margin-left: 0;
}
.btn-mrg2 {
  margin-left: 200px;
}
.cov-grp {
  background-color: white;
}
.small {
    height: 700px;
}
.large {
    height: 900px;
}
.zoom {
    transition: transform .2s;
}

.zoom:hover {
    transform: scale(1.2);
}

.pet-qt-mobile {
  max-width:  100px;
  object-fit: scale-down;
  margin-right: -2rem;
  z-index: 5;
}
</style>
